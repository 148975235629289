/**
 * scripts.js
 */
(function($) {
    $(document).ready(function() {

        /* PAGE VINHO VALID ANCHOR */
        var urlArr = $(location).attr('pathname').split('/');
        if( $.inArray('vinhos', urlArr ) ){
            var vinho = window.location.hash.substr(1);
            if( vinho ){
                $('.tiposdevinhos').removeClass('active');
                $('.btntipovinho').removeClass('active');
                $('.tiposdevinhos .vinho').removeClass('active');
                $('.btnvinho').removeClass('active');

                var tipoVinho = vinho.split('&');
                if( tipoVinho.length > 1 ){
                    $('.tiposdevinhos[data="'+tipoVinho[0]+'"]').addClass('active');
                    $('.btntipovinho[data="'+tipoVinho[0]+'"]').addClass('active');
                    $('.vinho[data="'+vinho+'"]').addClass('active');
                    $('.btnvinho[data="'+vinho+'"]').addClass('active');

                }else{
                    $('.tiposdevinhos[data="'+tipoVinho[0]+'"]').addClass('active');
                    $('.btntipovinho[data="'+tipoVinho[0]+'"]').addClass('active');
                    $('.tiposdevinhos .vinho:first-child').addClass('active');
                };
            }
        }
        /* PAGE VINHO VALID ANCHOR */

        /* SIDEBAR  */
        $("#sidebar").mCustomScrollbar({
            theme: "minimal"
        });

        $('#dismiss, .overlay').on('click', function () {
            $('#sidebar').removeClass('active');
            $('.overlay').removeClass('active');
        });

        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').addClass('active');
            $('.overlay').addClass('active');
            $('.collapse.in').toggleClass('in');
            $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        });

        $('.menu-item-has-children').on('click', function(){
            $(this).find('.dropdown-menu').toggle();
        });
        /* SIDEBAR  */
        /** Carrousel Vinhos HOME */
        $('.carouselHomevinhos').owlCarousel({
            navigation : true, // Show next and prev buttons
            items: 1,
            nav:true,
            slideSpeed : 300,
            paginationSpeed : 400,
            singleItem:true
        });
        /** Carrousel Galeria HOME */
        $('.carouselGaleriahome').owlCarousel({
            navigation : true, // Show next and prev buttons
            items: 4,
            nav:true,
            slideSpeed : 300,
            paginationSpeed : 400,
            singleItem:true,
            loop: true
        });
        /** Accordion Quem Somos */
        var section = $('li');
        function toggleAccordion() {
        section.removeClass('active');
        $(this).addClass('active');
        }
        section.on('click', toggleAccordion);

        /** page pessoa por trás */
        $('.clickPessoa').on('click', function (e) {
            e.preventDefault();
            var dataPessoa = $(this).attr('data');
            $('.pessoa').removeClass('active');
            $('.clickPessoa').removeClass('active');

            $('.pessoa[data="'+dataPessoa+'"]').addClass('active');
            $(this).addClass('active');
        });

        /** page Vinhos */
        $('.btntipovinho').on('click', function (e) {
            e.preventDefault();
            var tipovinho = $(this).attr('data');
            window.location = '#'+tipovinho;

            $('.tiposdevinhos').removeClass('active');
            $('.btntipovinho').removeClass('active');
            $('.tiposdevinhos .vinho').removeClass('active');
    
            $('.tiposdevinhos[data="'+tipovinho+'"]').addClass('active');
            $('.tiposdevinhos .vinho:first-child').addClass('active');

            $(this).addClass('active');
        });


        $('.btnvinho').on('click', function (e) {
            e.preventDefault();
            var vinho = $(this).attr('data');

            window.location = '#'+vinho;

            $('.vinho').removeClass('active');
            $('.btnvinho').removeClass('active');
    
            $('.vinho[data="'+vinho+'"]').addClass('active');
            $(this).addClass('active');
        });
        
        $('.carouselFotosVinho').owlCarousel({
            navigation : true, // Show next and prev buttons
            items: 1,
            nav:false,
            dots: true,
            slideSpeed : 300,
            paginationSpeed : 400,
            singleItem:true,
            loop: true
        });

        /** page Onde Comprar */
        $('.btnondecomprar').on('click', function (e) {
            e.preventDefault();
            var local = $(this).attr('data');

            $('.textoondecomprar').removeClass('active');
            $('.imgondecomprar').removeClass('active');
            $('.btnondecomprar').removeClass('active');
    
            $('.textoondecomprar[data="'+local+'"]').addClass('active');
            $('.imgondecomprar[data="'+local+'"]').addClass('active');

            $(this).addClass('active');
        });

    });
})(jQuery);


